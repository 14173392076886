@import '__importable.scss';
.optionsWrapper {
	padding: 1.6rem 0.8rem;

	position: sticky;
	top: $headerHeight;
	z-index: $zindex-3;
	background-color: $white;

	@media screen and (width >= $media-sm-max) {
		padding: 2.4rem 0.8rem;
	}

	@media screen and (min-width: $media-md-max) {
		padding-inline: 1.6rem;
	}

	@media screen and (width >= $media-xl) {
		padding: 2.4rem 3.2rem;
	}

	@media screen and (width >= $media-xxl) {
		padding: 3.2rem 6.4rem 2.4rem;
	}

	@media screen and (min-width: $max-width) {
		padding-inline: 12.8rem;
	}
}

.heading {
	margin: 0 0.8rem;
	padding-bottom: 0.8rem;
	border-bottom: 1px solid $gray-3;

	@media screen and (width >= $media-sm-max) {
		margin: 0 1.6rem;
	}

	@media screen and (width >= $media-xl) {
		margin: 0 3.2rem;
	}

	@media screen and (width >= $media-xxl) {
		margin: 0 6.4rem;
	}

	@media screen and (width >= $max-width) {
		margin: 0 12.8rem;
	}
}

.optionsContainer {
	overflow-x: auto;
	margin: 0 auto;

	@include noScrollBar;
}

.shapeContainer {
	white-space: nowrap;
	display: flex;
	align-items: center;
	background-color: $gray-2;
	padding: 1.6rem;
	gap: 0.8rem;
	border-radius: $radius-2;
	cursor: pointer;
	transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	user-select: none;
	-webkit-user-select: none;

	* {
		transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
	}

	&:focus-visible {
		background-color: $gray-3;
	}

	@media (hover: hover) {
		&:active {
			background-color: $blue-tint;

			span {
				color: $white !important;
			}
		}

		&:hover:not(:active):not(:focus-visible) {
			background-color: $gray-3;
		}
	}

	&.active {
		@include active;
		background-color: $blue-tint !important;
		padding: 1.6rem;
		box-shadow: none;

		span {
			color: $white !important;
		}
	}
	&.mediumPadding {
		padding: 0.8rem 1.6rem;
	}
}
